import React from 'react';
import Header from './Header';
import Home from './Home';


function Homepage() {
  return (
    <>
    <Header />
    <Home />
    </>
  )
}

export default Homepage
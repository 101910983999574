import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css'; // Optional custom styles
import logo from '../images/my_logo.png'; // Replace with your logo path

function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    };

    const handleLogin = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Show a SweetAlert2 loading dialog
        Swal.fire({
            title: 'Please wait...',
            text: 'We are logging you in.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        // Send a POST request to the PHP script
        fetch('https://swiftsendify.com/google_api/login.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            Swal.close(); // Close the loading dialog
            if (data.status === 'success') {
                // Save the user's name to session storage
                sessionStorage.setItem('userName', data.name);

                Swal.fire({
                    icon: 'success',
                    title: 'Login Successful',
                    text: data.message,
                }).then(() => {
                    navigate('/dashboard'); // Navigate to the dashboard page
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Login Failed',
                    text: data.message,
                });
            }
        })
        .catch(error => {
            Swal.close(); // Close the loading dialog
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong!',
            });
        });
    };

    return (
        <div className="login-container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-container col-md-6 d-flex flex-column justify-content-center align-items-center bg-light p-5">
                <img src={logo} alt="Logo" className="mb-4" style={{height: '127px' }} />
                <h2 className="mb-3">Login to your account</h2>
                <p className="text-muted mb-4">Welcome back! Please login to your account.</p>
                <button className="btn btn-outline-dark w-100 mb-3">
                    <i className="bi bi-google me-2"></i> Login with Google
                </button>
                <div className="d-flex align-items-center mb-3 w-100">
                    <hr className="flex-grow-1" />
                    <span className="px-3 text-muted">or</span>
                    <hr className="flex-grow-1" />
                </div>
                <form className="w-100" onSubmit={handleLogin}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email*</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            id="email" 
                            placeholder="Enter your email" 
                            value={formData.email} 
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password*</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            id="password" 
                            placeholder="Enter your password" 
                            value={formData.password} 
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-dark w-100">Login</button>
                </form>
                <p className="mt-3 text-muted">Don't have an account? <a href="/signup" className="text-decoration-none">Sign up</a></p>
            </div>
        </div>
    );
}

export default Login;

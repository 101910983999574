import React from 'react';
import './header.css'; // We will use minimal custom CSS
import logo from '../images/my_logo.png'; // Replace with your logo path
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Header() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Show a loading message
    Swal.fire({
      title: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    // Simulate a delay before navigation
    setTimeout(() => {
      Swal.close(); // Close the loading message
      navigate('/login'); // Navigate to the login page
    }, 2000); // Adjust the delay as needed (2000ms = 2 seconds)
  };

  return (
    <header className="bg-light shadow-sm py-2">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img src={logo} alt="KaweGPT" className="me-2" style={{ width: '15%' }} />
          <span className="h5 mb-0 fw-bold">PDFtoAUDIO</span>
        </div>
        <nav className="d-none d-md-block">
          <ul className="nav">
            <li className="nav-item">
              <a href="#about" className="nav-link">About</a>
            </li>
            <li className="nav-item">
              <a href="#resources" className="nav-link">Resources</a>
            </li>
            <li className="nav-item">
              <a href="#connect" className="nav-link">Connect</a>
            </li>
          </ul>
        </nav>
        <div className="d-flex">
          <button className="btn btn-outline-dark me-2" onClick={handleLoginClick}>Login</button>
          <button className="btn btn-primary" onClick={handleLoginClick}>Start for free <i className="bi bi-arrow-right"></i></button>
        </div>
      </div>
    </header>
  );
}

export default Header;

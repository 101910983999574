import React, { useState, useEffect } from 'react';
import './dashboard.css';
import logo from '../images/my_logo.png'; // Replace with your logo path
import { FaUserCircle, FaSignOutAlt, FaHome } from 'react-icons/fa'; // Import the necessary icons
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the user's name from session storage
        const name = sessionStorage.getItem('userName');
        if (name) {
            setUserName(name);
        }
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

   /*  const handleProfileClick = () => {
        // Implement profile navigation logic here
    };

    const handleSettingsClick = () => {
        // Implement settings navigation logic here
    };

    const handleHelpClick = () => {
        // Implement help navigation logic here
    }; */

    const handleHomeClick = () => {
        navigate('/homepage');
    };

    const handleLogoutClick = () => {
        // Implement logout logic here
        navigate('/login');
    };

    const handleGetStartedClick = () => {
        navigate('/upload'); // Replace '/login' with your actual login route
    };

    return (
        <>
            <header className="bg-light shadow-sm py-2">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={logo} alt="KaweGPT" className="me-2" style={{ width: '40px' }} />
                        <span className="h5 mb-0 fw-bold">PDFtoAUDIO</span>
                    </div>

                    <div className="d-flex align-items-center position-relative">
                        <span className="me-2">{userName}</span>
                        <FaUserCircle size={30} onClick={toggleDropdown} style={{ cursor: 'pointer' }} />
                        {dropdownOpen && (
                            <div className="dropdown-menu dropdown-menu-end show" style={{ position: 'absolute', top: '160%', right: 0 }}>
                                {/* <button className="dropdown-item d-flex align-items-center" onClick={handleProfileClick}>
                                    <FaUser className="me-2" /> Profile
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleSettingsClick}>
                                    <FaCog className="me-2" /> Settings
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleHelpClick}>
                                    <FaQuestionCircle className="me-2" /> Help
                                </button> */}
                                <button className="dropdown-item d-flex align-items-center" onClick={handleHomeClick}>
                                    <FaHome className="me-2" /> Homepage
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleLogoutClick}>
                                    <FaSignOutAlt className="me-2" /> Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            <div className="home-container d-flex flex-column align-items-center justify-content-center text-center min-vh-100">
                <img src={logo} alt="Logo" className="mb-4" style={{ height: '30%' }} />
                <div className="text-content mb-4">
                    <h1 className="display-4 fw-bold">
                        Convert Your PDFs to <span className="text-primary">Audio</span><br /> Effortlessly
                    </h1>
                    <p className="lead">Turn your documents into audio files in just a few clicks. Perfect for on-the-go learning!</p>
                </div>
                <div className="button-group mb-4">
                    <button className="btn btn-primary btn-lg me-3" onClick={handleGetStartedClick}>
                        <i className="bi bi-upload me-2"></i> Upload Document
                    </button>
                    <button className="btn btn-outline-dark btn-lg" onClick={handleGetStartedClick} >
                        Get Started <i className="bi bi-arrow-right"></i>
                    </button>
                </div>
                <div className="supported-formats">
                    <h5 className="mb-3">Supported Formats</h5>
                    <div className="d-flex justify-content-center">
                        <span className="badge bg-gray text-light border me-2">TXT</span>
                        <span className="badge bg-gray text-light border me-2">PPT</span>
                        <span className="badge bg-light text-dark border me-2">PDF</span>
                        <span className="badge bg-gray text-light border me-2">PPTX</span>
                        <span className="badge bg-gray text-light border me-2">EPUB</span>
                        <span className="badge bg-gray text-light border">RTF</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPlay, FaPause, FaStop, FaArrowLeft } from 'react-icons/fa';
import './reader.css'; // Custom styles for the reader component
import logo from '../images/my_logo.png'; // Replace with your logo path

function Reader() {
    const location = useLocation();
    const navigate = useNavigate();
    const [textContent, setTextContent] = useState('');
    const [pdfTitle, setPdfTitle] = useState('');
    const [utterance, setUtterance] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (location.state) {
            setTextContent(location.state.textContent || '');
            setPdfTitle(location.state.pdfTitle || 'No Title');
        }
    }, [location.state]);

    useEffect(() => {
        if (textContent) {
            const newUtterance = new SpeechSynthesisUtterance(textContent);
            newUtterance.lang = 'en-US';
            newUtterance.onend = () => setIsPlaying(false); // Reset playing status when speech ends
            newUtterance.onpause = () => setIsPlaying(false); // Reset playing status when speech is paused
            newUtterance.onstart = () => setIsPlaying(true); // Set playing status when speech starts
            setUtterance(newUtterance);
        }
    }, [textContent]);

    const handlePlay = () => {
        if (utterance) {
            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel(); // Stop any ongoing speech before starting a new one
            }
            window.speechSynthesis.speak(utterance);
        }
    };

    const handlePause = () => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.pause();
            setIsPlaying(false);
        }
    };

    const handleStop = () => {
        window.speechSynthesis.cancel();
        setIsPlaying(false);
    };

    const handleBack = () => {
        navigate('/upload'); // Navigate back to the uploads page
    };

    return (
        <Container className="reader-container mt-5">
            <div className="logo-container">
                <img src={logo} alt="Logo" className="logo-image" />
            </div>
            <Row>
                <Col>
                    <Card className="text-content-card">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <button className="btn btn-outline-secondary" onClick={handleBack}>
                                    <FaArrowLeft /> Back
                                </button>
                                <h5 className="mb-0">{pdfTitle}</h5> {/* Display PDF title */}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-content">
                                <p>{textContent}</p>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary me-2" onClick={handlePlay} disabled={isPlaying}>
                                    <FaPlay /> Play
                                </button>
                                <button className="btn btn-warning me-2" onClick={handlePause} disabled={!isPlaying}>
                                    <FaPause /> Pause
                                </button>
                                <button className="btn btn-danger" onClick={handleStop}>
                                    <FaStop /> Stop
                                </button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Reader;

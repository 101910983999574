import React from 'react';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from '../images/my_logo.png'; // Replace with your logo path
import './home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const handleUploadClick = () => {
    Swal.fire({
      title: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    // Simulate a delay (e.g., 2 seconds) before showing the login-required modal
    setTimeout(() => {
      Swal.fire({
        title: 'Login Required',
        text: 'You need to login before you can upload your document for processing.',
        icon: 'warning',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary me-2',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/signup'); // Navigate to login page
        }
      });
    }, 2000); // 2 seconds delay
  };

  const handleLoginClick = () => {
    navigate('/signup'); // Replace '/login' with your actual login route
  };

  return (
    <div className="home-container d-flex flex-column align-items-center justify-content-center text-center min-vh-100">
      <img src={logo} alt="Logo" className="mb-4" style={{ height: '30%' }} />
      <div className="text-content mb-4">
        <h1 className="display-4 fw-bold">
          Convert PDFs to <span className="text-primary">Audio</span>,<br /> Listen with Ease
        </h1>
        <p className="lead">Transform your documents into audio files and listen on the go.</p>
      </div>
      <div className="button-group mb-4">
        <button className="btn btn-primary btn-lg me-3" onClick={handleUploadClick}>
          <i className="bi bi-upload me-2"></i> Upload Document
        </button>
        <button className="btn btn-outline-dark btn-lg" onClick={handleLoginClick}>
          Start for free <i className="bi bi-arrow-right"></i>
        </button>
      </div>
      <div className="supported-formats">
        <h5 className="mb-3">Supported formats</h5>
        <div className="d-flex justify-content-center">
          <span className="badge bg-gray text-light border me-2">TXT</span>
          <span className="badge bg-gray text-light border me-2">PPT</span>
          <span className="badge bg-light text-dark border me-2">PDF</span>
          <span className="badge bg-gray text-light border me-2">PPTX</span>
          <span className="badge bg-gray text-light border me-2">EPUB</span>
          <span className="badge bg-gray text-light border">RTF</span>
        </div>
      </div>
    </div>
  );
}

export default Home;

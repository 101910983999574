import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './uploads.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/my_logo.png'; // Replace with your logo path
import { FaUserCircle, FaSignOutAlt, FaHome, FaFileAlt, FaSearch } from 'react-icons/fa';

// Function to extract text and title from a PDF file
const extractTextFromPDF = async (file) => {
    const pdfjsLib = window['pdfjs-dist/build/pdf'];
    const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
    const pdf = await loadingTask.promise;
    let extractedText = '';
    let title = '';

    const metadata = await pdf.getMetadata();
    title = metadata?.info?.Title || 'Untitled'; // Extract title from metadata

    for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        extractedText += `${pageText} `;
    }

    return { title, text: extractedText };
};

function Uploads() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [analyzing, setAnalyzing] = useState(false);
    const [textContent, setTextContent] = useState('');
    const [pdfTitle, setPdfTitle] = useState('');

    const navigate = useNavigate();

    const [userName, setUserName] = useState('');

    useEffect(() => {
        // Retrieve the user's name from session storage
        const name = sessionStorage.getItem('userName');
        if (name) {
            setUserName(name);
        }
    }, []);

    useEffect(() => {
        if (analyzing) {
            const timer = setTimeout(() => {
                navigate('/reader', { state: { textContent, pdfTitle } }); // Pass textContent and pdfTitle to Reader page
            }, 5000);
            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [analyzing, navigate, textContent, pdfTitle]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

  /*   const handleProfileClick = () => {
        // Implement profile navigation logic here
    };

    const handleSettingsClick = () => {
        // Implement settings navigation logic here
    };

    const handleHelpClick = () => {
        // Implement help navigation logic here
    }; */

    const handleHomeClick = () => {
        navigate('/homepage');
    };

    const handleLogoutClick = () => {
        navigate('/login');
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setFileSize((file.size / (1024 * 1024)).toFixed(3)); // Convert to MB
            setUploading(true);
            simulateUpload();

            // Extract title and text from the PDF
            const { title, text } = await extractTextFromPDF(file);
            setTextContent(text);
            setPdfTitle(title);

            // Automatically start analyzing after upload
            setAnalyzing(true);
        }
    };

    const simulateUpload = () => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            if (progress >= 100) {
                clearInterval(interval);
                progress = 100;
            }
            setUploadProgress(progress);
        }, 500);
    };

    const handleDelete = () => {
        setUploading(false);
        setUploadProgress(0);
        setFileName('');
        setFileSize(0);
        setTextContent('');
        setPdfTitle('');
    };

    const handleAnalyzeClick = () => {
        setAnalyzing(true);
    };

    return (
        <>
            <header className="bg-light shadow-sm py-2">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={logo} alt="KaweGPT" className="me-2" style={{ width: '40px' }} />
                        <span className="h5 mb-0 fw-bold">PDFtoAUDIO</span>
                    </div>

                    <div className="d-flex align-items-center position-relative">
                    <span className="me-2">{userName}</span>
                        <FaUserCircle size={30} onClick={toggleDropdown} style={{ cursor: 'pointer' }} />
                        {dropdownOpen && (
                            <div className="dropdown-menu dropdown-menu-end show" style={{ position: 'absolute', top: '160%', right: 0 }}>
                                {/* <button className="dropdown-item d-flex align-items-center" onClick={handleProfileClick}>
                                    <FaUser className="me-2" /> Profile
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleSettingsClick}>
                                    <FaCog className="me-2" /> Settings
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleHelpClick}>
                                    <FaQuestionCircle className="me-2" /> Help
                                </button> */}
                                <button className="dropdown-item d-flex align-items-center" onClick={handleHomeClick}>
                                    <FaHome className="me-2" /> Homepage
                                </button>
                                <button className="dropdown-item d-flex align-items-center" onClick={handleLogoutClick}>
                                    <FaSignOutAlt className="me-2" /> Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            <div className="home-container d-flex flex-column align-items-center justify-content-center text-center min-vh-100">
                <img src={logo} alt="Logo" className="mb-4" style={{ height: '30%' }} />

                {analyzing ? (
                    <div className="analyzing-container">
                        <div className="search-icon-container">
                            <FaFileAlt className="file-icon" style={{ color: 'black' }} />
                            <FaSearch className="search-icon-overlay" style={{ color: '#fff' }} />
                        </div>
                        <h2>Analyzing Document</h2>
                        <ProgressBar animated now={uploadProgress} style={{ width: '100%', maxWidth: '500px' }} />
                    </div>
                ) : (
                    <Container className="custom-margin">
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Card className="p-4 upload-card">
                                    {!uploading ? (
                                        <Tabs defaultActiveKey="upload-document" id="upload-tabs" className="mb-3">
                                            <Tab eventKey="upload-document" title="Upload Document">
                                                <div className="upload-area text-center">
                                                    <input type="file" className="d-none" id="fileInput" onChange={handleFileUpload} />
                                                    <label htmlFor="fileInput" className="upload-label">
                                                        <div className="upload-icon mb-3">
                                                            <i className="bi bi-cloud-arrow-up"></i>
                                                        </div>
                                                        <p>Click to upload or drag and drop</p>
                                                        <small>Supported formats: .pdf</small>
                                                    </label>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="upload-url" title="Upload from URL">
                                                <div className="upload-area text-center">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter URL"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    ) : (
                                        <div className="uploading-status">
                                            <p>{fileName}</p>
                                            <p>{fileSize}MB</p>
                                            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                                            <button className="btn btn-link text-danger mt-3" onClick={handleDelete}>
                                                Delete
                                            </button>
                                            <div className="d-flex justify-content-center">
                                                <button className="btn btn-outline-dark btn-lg" onClick={handleAnalyzeClick}>
                                                    Analyze File <i className="bi bi-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </>
    );
}

export default Uploads;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './signup.css'; // Optional custom styles
import logo from '../images/my_logo.png'; // Replace with your logo path

function Signup() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.id]: event.target.value
        });
    };

    const handleSignUp = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Show a SweetAlert2 loading dialog
        Swal.fire({
            title: 'Please wait...',
            text: 'We are setting up your account.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        // Send a POST request to the PHP script
        fetch('https://swiftsendify.com/google_api/index.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            Swal.close(); // Close the loading dialog
            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Account Created',
                    text: data.message,
                }).then(() => {
                    navigate('/dashboard'); // Navigate to the dashboard page
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Sign-Up Failed',
                    text: data.message,
                });
            }
        })
        .catch(error => {
            Swal.close(); // Close the loading dialog
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong!',
            });
        });
    };

    return (
        <div className="signup-container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-container col-md-6 d-flex flex-column justify-content-center align-items-center bg-light p-5">
                <img src={logo} alt="Logo" className="mb-4" style={{height: '127px' }} />
                <h2 className="mb-3">Create your account</h2>
                <p className="text-muted mb-4">Let’s get started with your 30 days free trial</p>
                <button className="btn btn-outline-dark w-100 mb-3">
                    <i className="bi bi-google me-2"></i> Sign Up with Google
                </button>
                <div className="d-flex align-items-center mb-3 w-100">
                    <hr className="flex-grow-1" />
                    <span className="px-3 text-muted">or</span>
                    <hr className="flex-grow-1" />
                </div>
                <form className="w-100" onSubmit={handleSignUp}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name*</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="name" 
                            placeholder="Enter your name" 
                            value={formData.name} 
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email*</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            id="email" 
                            placeholder="Enter your email" 
                            value={formData.email} 
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password*</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            id="password" 
                            placeholder="Enter your password" 
                            value={formData.password} 
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="terms" />
                        <label className="form-check-label" htmlFor="terms">
                            I agree to all terms, Privacy Policy and Fees
                        </label>
                    </div>
                    <button type="submit" className="btn btn-dark w-100">Sign Up</button>
                </form>
                <p className="mt-3 text-muted">Already have an account? <a href="/login" className="text-decoration-none">Log in</a></p>
            </div>
        </div>
    );
}

export default Signup;
